<template>
	<v-sheet>
		<v-toolbar flat>
			<v-toolbar-title>工地列表</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn 
				v-if="user && user.role === 'admin'"
				color="primary"
				@click="onCreateNewSite"
				elevation="0">
				<v-icon>mdi-plus</v-icon> 添加新工地
			</v-btn>
		</v-toolbar>
		<v-divider></v-divider>		
		<div class="d-flex justify-end py-2 pr-4">
			<div style="max-width:150px;">
				  <v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
							color="primary"
							text
							small
							tile
							v-bind="attrs"
							v-on="on"
							>
							<v-icon small left>
								mdi-filter-variant
							</v-icon>
							过滤状态: {{getStatusText}}
							<v-icon small>
								mdi-menu-down
							</v-icon>
							</v-btn>
						</template>
						<v-list flat dense>
							<v-list-item
								v-for="(item, index) in statusText"
								:key="index"
								@click="updateStatus(item.value)"
								>
							<v-list-item-subtitle class="body-2">{{ item.text }}</v-list-item-subtitle>
							</v-list-item>
						</v-list>
				</v-menu>
			</div>
			<v-divider vertical class="mx-3"></v-divider>
			<div style="max-width:150px;">
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
						color="primary"
						text
						small
						tile
						v-bind="attrs"
						v-on="on"
						>
							每页显示 {{itemsPerPage}}
						<v-icon small>
							mdi-menu-down
						</v-icon>
						</v-btn>
					</template>
					<v-list flat dense>
						<v-list-item
							v-for="(item, index) in items"
							:key="index"
							@click="itemsPerPage = parseInt(item, 10)"
							>
						<v-list-item-subtitle class="body-2">{{ item }}</v-list-item-subtitle>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>
		</div>
		<v-divider></v-divider>
		<template v-if="loading">
			<v-sheet class="d-flex flex-column justify-center align-center" height="60vh">
				<v-progress-circular
					:size="70"
					:width="3"
					color="primary"
					indeterminate
				></v-progress-circular>
				<div class="subtitle-1 mt-4 ">刷新数据中</div>
			</v-sheet>
		</template>
		<template v-else>
			<v-data-table
				:headers="headers"
				:items="sites"
				:items-per-page="itemsPerPage"
				:page.sync="currentPage"
				hide-default-footer
				@update:options="onUpdatePageOptions"
				>
				<template v-slot:item.actions="{item}">
					<v-btn 
						color="primary"
						elevation="0"
						small
						@click="onUpdateSite(item)"
					>
						<v-icon small left>
							mdi-eye
						</v-icon>
						编辑工地
					</v-btn>
				</template>
			</v-data-table>
		</template>
		<v-divider></v-divider>
		<div class="d-flex justify-center py-2 ">
			<v-pagination v-model="currentPage" :length="totalPages" @input="onUpdatePage"></v-pagination>
		</div>
		<v-divider></v-divider>
		<v-dialog v-model="dialogSiteForm" max-width="480">
			<v-card>
				<v-card-title>
					 {{ isSiteUpdate ? '编辑工地' : '添加新工地' }}
				</v-card-title>
				<v-divider></v-divider>
				<div class="pa-3">
					<site-form @on-submit-success="onSiteCreated" :isUpdate="isSiteUpdate" :site="editSiteData" />
				</div>
			</v-card>
		</v-dialog>
		<!--- Snackbar --->
		<v-snackbar
			absolute
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
	</v-sheet>
</template>

<script>

import SiteServices from '../../services/Site';
import siteForm from '../../components/Site/SiteForm';
import { mapState } from 'vuex';

export default {
	name: 'Site',
	components: {
		siteForm
	},
	data: () => ({
		sites: [],
		itemsPerPage: 25,
		totalPages: 1,
		currentPage: 1,
		items: [ 10, 15, 25],
		statusText: [
			{ text: '进行中', value: 0 },
			{ text: '已完工', value: 1 },
		],
		status: 0,
		fab: false,
		headers: [
			{ text: '编码', value: 'id',align: 'start', },
			{ text: '工地名称', value: 'name' },
			{ text: '创建人', value: 'creator.name' },
	        { text: '功能', value: 'actions', sortable: false, width: 120 }
		],
		loading: false,
		dialogSiteForm: false,
		isSiteUpdate: false,
		editSiteData: null,
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
	}),
	computed: {
		...mapState('user', ['user'],),
		getStatusText(){
			let returnText;
			this.statusText.forEach(status=>{
				if(status.value === this.status){
					returnText = status.text;
				}
			})
			return returnText;
		}
	},
	watch: {
		loading(){
			if(this.loading){
				setTimeout( ()=>{
					this.loading = false;
				}, 1000)
			}
		}
	},
	methods: {
		async getSiteList() {
			try {
				let response = await SiteServices.getSiteList(this.currentPage, this.itemsPerPage, this.status);
				if(response.data.statusCode === 200){
					this.sites = response.data.data.items;
					this.itemsPerPage = response.data.data.max_items_per_page;
					this.totalPages = response.data.data.total_pages;
					this.currentPage = response.data.data.current_page;
				}
			} catch(error) {
				this.snackbar.message = `错误信息：${error.data.message}`;
				this.snackbar.color = 'error';
				this.snackbar.model = true;
			}
		},
		onUpdatePageOptions(option){
			this.itemsPerPage = option.itemsPerPage;
			this.currentPage = option.page;
			this.getSiteList();
		},
		onUpdatePage(page){
			this.currentPage = page;
			this.getSiteList();
		},
		updateStatus(event){
			this.status = event;
			this.currentPage = 1;
			this.getSiteList();
		},
		onSiteCreated(){
			this.dialogSiteForm = false;
			this.loading = true;
		},
		onCreateNewSite(){
			this.isSiteUpdate = false;
			this.editSiteData = {
				name: '',
				status: this.status
			}
			this.dialogSiteForm = true;
		},
		onUpdateSite(siteData) {
			this.isSiteUpdate = true;
			this.editSiteData = siteData;
			this.editSiteData.status = this.status;
			this.dialogSiteForm = true;
		}
	},
	created(){
		this.loading = true;
		this.getSiteList();
	}
}
</script>
