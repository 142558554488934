import Api from '@/services/Api';

export default {
	createSite(body) {
		return Api().post(`/site`, JSON.stringify(body));
	},
	getSiteList(page, itemsPerPage, isArchived) {
		return Api().get(`/site??page=${page}&per_page=${itemsPerPage}&archived=${isArchived}`)
	},
	getSiteById(site_id) {
		return Api().get(`/site/${site_id}`);
	},
	updateSiteById(site_id, body) {
		return Api().patch(`/site/${site_id}`, JSON.stringify(body));
	}
}