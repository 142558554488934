<template>
	<div>
		<v-form
			ref="form"
			class="pa-3"
			v-model="valid"
			lazy-validation
			@submit.prevent="onFormSubmit"
		>
			<v-text-field
				v-model="siteData.name"
				:counter="3"
				outlined
				:rules="nameRules"
				label="工地名称"
				required
			></v-text-field>

			<template v-if="isUpdate">
				<div class="subtitle-2 mb-2">选择工地状态:</div>
				<v-btn-toggle
					v-model="siteData.status"
					color="primary"
					class="mb-2"
					mandatory
					style="width:100%"
					@change="onStatusUpdate($event)"
				>
				    <v-btn :value="0" class="flex-grow-1">
						进行中
					</v-btn>
					<v-btn :value="1"  class="flex-grow-1">
						已完工
					</v-btn>
				</v-btn-toggle>
			</template>
			<v-divider class="my-2"></v-divider>
			<div class="d-flex justify-space-between mt-4">
				<v-btn
					color="error"
					text
					@click="reset"
					>
					重新填写
				</v-btn>
				<v-btn
					:disabled="!valid"
					color="success"
					elevation="0"
					@click="validate"
					type="submit"
				>
					{{submitText}}
				</v-btn>
			</div>
		</v-form>
		<v-snackbar
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
		<!--- loading overlay ---->
		<v-overlay :value="overlay">
			<v-progress-circular
			:size="70"
			:width="7"
			color="primary"
			indeterminate
			></v-progress-circular>
		</v-overlay>
	</div>
</template>

<script>
import SiteServices from '../../services/Site';

export default {
	name: 'sitetFrom',
	data: () => ({
		valid: true,
		nameRules: [
			v => !!v || '请输入工地名称',
			v => (v && v.length >= 2) || '工地名称至少2个字',
		],
		requiredRules: [
			v => !!v || '必填内容',
		],
		budgetRules: [
			v => !!v || '必填内容',
			v => (v && !isNaN(v)) || `请输入数字。例如：30`,
		],
		dateMenu: false,
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		siteData: {
			id: null,
			name: '',
			status: 0,
		},
		overlay: false
	}),
	props: {
		site: {
			type: Object,
			required: false,
		},
		isUpdate: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	watch: {
		site(){
			this.setEditSiteData();
		}
	},
	created(){
		this.setEditSiteData();
	},
	computed: {
		submitText(){
			return this.isUpdate ? '更新工地' : '添加工地';
		}
	},
	methods: {
		setEditSiteData(){
			this.siteData = this.site;
		},
		validate () {
			this.$refs.form.validate();
		},
		reset () {
			this.$refs.form.reset()
		},
		resetValidation () {
			this.$refs.form.resetValidation()
		},
		formatDate (date) {
			if (!date) return null

			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		onFormSubmit(){
			this.overlay = true;
			//create paylod body here
			const body = {
				"name": this.siteData.name,
			}
			if(this.isUpdate){
				body.archived = this.siteData.status;
				this.updateSite(body);
			} else {
				this.createSite(body);
			}
		},
		async updateSite(body){
			try {
				let response = await SiteServices.updateSiteById(this.siteData.id, body);

				if(response.data.statusCode === 200){
					this.snackbar.message = `工地信息更新成功`;
					this.snackbar.color = 'success';
					this.snackbar.model = true;
					setTimeout(()=>{
						this.overlay = false;
						this.$emit('on-submit-success');
					}, 1000)
				}
			} catch(error){
					this.snackbar.message = `错误信息：${error.data.message}`;
					this.snackbar.color = 'error';
					setTimeout(()=>{
						this.overlay = false;
						this.snackbar.model = true;
					}, 1000)
			}
		},
		async createSite(body){
			try {
				let response = await SiteServices.createSite(body);

				if(response.data.statusCode === 200){
					this.snackbar.message = `工地创建成功`;
					this.snackbar.color = 'success';
					this.snackbar.model = true;
					setTimeout(()=>{
						this.reset();
						this.overlay = false;
						this.$emit('on-submit-success');
					}, 1000)
				}
			} catch(error){
					this.snackbar.message = `错误信息：${error.data.message}`;
					this.snackbar.color = 'error';
					setTimeout(()=>{
						this.overlay = false;
						this.snackbar.model = true;
						this.reset();
					}, 1000)
			}
		},
		onStatusUpdate(event){
			this.siteData.status = event;
		}
	}
}
</script>
